:root {
    --primary-color: red;
}


$primary-color: #3498db;


.contact{
    background-color: var(--primary-color);
}