

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #333;
  color: white;
  width: 100%;
  margin: 0;
  position: sticky;
  top: 0;
  z-index: 1000;
  height: 64px;
  /* border: 1px solid; */
}

.header-title {
  margin-left: auto;
}

.sidebar-toggle {
  display: none;
  font-size: 24px;
  cursor: pointer;
  position: fixed; 
  top: 20px;
  left: 20px;
  z-index: 1000;
  color: white; /* Adjust color to ensure visibility */
}

.dropdown-toggle {
  font-size: 20;
  cursor: pointer;
  margin-left: 20px;
  background-color: lightgreen;
  color: white; 
  padding: 0px 5px 0px 5px;
  border: 2px solid white; 
  border-radius: 4px; 
 font-weight: bold;
}

.user-icon {
  font-size: 24px;
  margin-left: 20px;
  cursor: pointer;
}
.custom-drawer {
  position: absolute;
  top: 67px;
  overflow-y: hidden
}

@media (max-width: 768px) {
  .header {
    flex-wrap: wrap; 
    justify-content: flex-end; 
    position: relative; 
  }

  .header-title {
    font-size: 1.25rem;
    margin-right: auto; 
  }

  .sidebar-toggle {
    display: block;
    margin-left: 20px; 
  }

  nav ul {
    flex-direction: column;
    width: 100%;
  }

  nav li {
    margin: 5px 0;
  }

  nav a {
    padding: 10px;
    width: 100%;
    text-align: center;
  }
}
