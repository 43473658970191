.sidebar-container {
    display: flex;
}

.content {
    margin-left: 230px;
    width: calc(100% - 230px);
    transition: margin-left 0.3s ease;
}

@media (max-width: 768px) {
    .content {
        margin-left: 0;
        width: 100%;
    }
}