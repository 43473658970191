@keyframes water-drop {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

.animate-water-drop {
    animation: water-drop 3.9s infinite ease-in-out;
}
.animate__animated {
    animation-duration: 3s; /* Set to your preferred duration for each cycle */
    animation-delay: 0.2s; /* Optional delay before the animation starts */
    animation-fill-mode: both; /* Retain styles at the end of the animation */
    animation-iteration-count: infinite; /* Loop the animation infinitely */
}

@keyframes borderAnimation {
    0% {
        border-color: orange;
        border-radius: 8px;
    }
    50% {
        border-color: purple;
        border-radius: 16px;
    }
    100% {
        border-color: orange;
        border-radius: 8px;
    }
}

.animated-border {
    animation: borderAnimation 2s infinite;
}

@keyframes pulse {
    0%, 100% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.1);
        opacity: 0.8;
    }
}

.pulse-animation {
    animation: pulse 1.5s infinite;
}

@keyframes flash {
    0% { opacity: 1; }
    50% { opacity: 0.3; }
    100% { opacity: 1; }
}

.flash-animation {
    animation: flash 3s infinite;
}

@keyframes borderAnimation {
    0% {
        border-color: orange;
        border-width: 2px;
    }
    50% {
        border-color: purple;
        border-width: 3px;
    }
    100% {
        border-color: orange;
        border-width: 2px;
    }
}

.animated-dotted-border {
    border-style: dashed;
    animation: borderAnimation 2s infinite;
}
