.invoice-box {
    width: 100%;
    max-width: 800px;
    margin: auto;
    padding: 20px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    font-size: 16px;
    line-height: 24px;
    font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    color: #555;
    page-break-inside: avoid; /* Prevent breaking within the invoice */
  }
  
  .invoice-box table {
    width: 100%;
    line-height: inherit;
    text-align: left;
    border-collapse: collapse; /* Ensures borders are displayed neatly */
  }
  
  .invoice-box table td, .invoice-box table th {
    border: 1px solid #ddd; /* Add borders around all table cells */
    padding: 8px;
    vertical-align: top;
  }
  
  .invoice-box table tr.heading th {
    background: #eee;
    border-bottom: 2px solid #ddd;
    font-weight: bold;
    padding: 15px;
  }
  
  .invoice-box table tr.details td {
    padding-bottom: 20px;
  }
  
  .invoice-box table tr.item td {
    border-bottom: 1px solid #eee;
  }
  
  .invoice-box table tr.item.last td {
    border-bottom: none;
  }
  
  .invoice-box table tr.total td:nth-child(2) {
    border-top: 2px solid #eee;
    font-weight: bold;
  }
  
  .summary-table, .bank-details {
    margin-top: 20px;
  }
  
  .summary-table td, .bank-details td {
    border: 1px solid #ddd; /* Ensure borders in summary and bank details */
  }
  
  .print-btn {
    display: block;
    width: 150px;
    margin: 20px auto;
    padding: 10px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }

  #Inovice_heading{
    text-align: center;
    margin-top:7%;
  }
  
  .print-btn:hover {
    background-color: #218838;
  }
  
  /* Add print-specific styles */
  @media print {
    body {
      zoom: 80%; /* Scale down content slightly to fit everything on one page */
    }
    .print-btn {
      display: none; /* Hide the print button when printing */
    }
  
    .invoice-box {
      page-break-inside: avoid; /* Prevent breaking inside the invoice */
      break-inside: avoid; /* For modern browsers */
    }
  
    .invoice-box table,
    .summary-table,
    .bank-details {
      page-break-inside: avoid; /* Prevent breaking inside tables and summary sections */
      break-inside: avoid;
    }
  
    .invoice-box table td, .invoice-box table th {
      border: 1px solid #000; /* Ensure borders are visible in print */
    }
  }
  