html {
    scroll-behavior: smooth;
}

.UnAuthHeaderContainer {
    width: 100%;
    height: 10vh; /* Maintain a fixed height */
    display: flex;
    justify-content: space-between;
    align-items: center; /* Center items vertically */
    font-weight: bold;
    font-size: 16px;
    color: black;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    /* border: 1px solid; */
}

.logoImageContainer {
    width: 12%;
    height: 10vh;
    margin-left: 2%;
}

.logoImage {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
}

.HeaderList {
    /* margin-top: 1.5%; */
    width: 70%;
    display: flex;
    justify-content: space-evenly;
    padding: 0.9%;
    background-color: rgba(244, 235, 235, 0.9);
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    /* border-bot-right-radius: 50px; */


    box-shadow: rgba(172, 82, 247, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.Span {
    font-size: large;
    font-family: 'Times New Roman', Times, serif;
}

a:hover {
    text-decoration: underline;
    color: purple;
}

.menu-icon {
    display: none; /* Hide menu icon by default */
    flex-direction: column;
    cursor: pointer;
    /* margin-right: 2%; */
}

.bar {
    height: 4px;
    width: 25px;
    background-color: black;
    margin: 3px 0;
    transition: all 0.3s;
    
}

.cross .bar {
    background-color: purple; /* Change color for cross */
}

.cross .bar:nth-child(1) {
    transform: translate(-50%, -50%) rotate(45deg); /* Rotate for cross effect */
}

.cross .bar:nth-child(2) {
    transform: translate(-50%, -50%) rotate(-45deg); /* Rotate for cross effect */
}

.UnAuthHeaderContainer {
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    background-color: transparent; /* or your initial background */
}

.UnAuthHeaderContainer.scrolled {
    background-color: white; /* change to the desired color */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* optional shadow effect */
}

/* UnAuthHeader.css */
.HeaderList {
    /* Add your styles for the header list */
}

.UnAuthHeaderContainer {
    position: fixed; /* If you want it to stay at the top */
    width: 100%;
    z-index: 1000;
}

.scrolled {
    background-color: rgba(255, 255, 255, 0.9); /* Example background when scrolled */
    transition: background-color 0.3s ease;
}

@media (max-width: 768px) {
    .UnAuthHeaderContainer {
        flex-direction: column; /* Stack elements vertically */
        height: auto; /* Allow height to adjust */
        padding: 0.5rem 2%; /* Add padding for aesthetics */
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    }

    .logoImageContainer {
        width: 30%; /* Adjust width for smaller screens */
        margin: 0 auto; 
        margin-top: -5%;
        margin-left: 2%;
    }

    .menu-icon {
        position: absolute; /* Position it absolutely within the container */
        top: 10px; /* Adjust as needed */
        right: 10%; /* Adjust as needed */
        display: flex; /* Show menu icon */
        flex-direction: column;
        z-index: 1001; /* Ensure it appears above other elements */

    }

    .HeaderList {
        display: none; /* Hide by default */
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 0;
        margin-top: 0px;
        background-color: rgba(250, 246, 246, 0.9);
        transition: max-height 0.3s ease-in-out; /* Smooth transition for height */
        overflow: hidden; /* Hide overflowing content */
    }

    .HeaderList.open {
        display: flex; /* Show when open */
    }

    .HeaderList li {
        margin: 0.5rem 0;
        font-size: large;
    }
}