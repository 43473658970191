.sidebar-container {
  display: flex;
}



.content {
  margin-left: 230px;
  width: calc(100% - 230px);
  transition: margin-left 0.3s ease;
}


.unAuthBg{
  background-image: url('../images/bubble-color.avif');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  padding-top: 8vh;
  min-height: 100vh;
}

@media (max-width: 768px) {
  .content {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
  }
}